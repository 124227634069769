import React from 'react'
import Navbar from '../components/navbar';
import Footer from '../components/FooterNew';
import Carousel from 'react-bootstrap/Carousel';

export default function Charities() {
  return (
    <>
    <Navbar/>
    <div className='container-fluid'>
     
    <Carousel fade>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="https://source.unsplash.com/800x200/?kids,charity"
          alt="First slide"
        />
        <Carousel.Caption>
          <h3>Charity</h3>
          <p>Please help to the charities</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="https://source.unsplash.com/800x200/?charity,children,kids"
          alt="Second slide"
        />

        <Carousel.Caption>
          <h3>Charity</h3>
          <p>Please Do not Waste food</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="https://source.unsplash.com/800x200/?food,money"
          alt="Third slide"
        />

        <Carousel.Caption>
          <h3>Charity</h3>
          <p>
         Please help to the charities
          </p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
    <div className='container'>
      <div className='row'>
        <div className='col-lg-5'></div>
        <div className='col-lg-4 pl-5'>
          <h2>Charity</h2>
        </div>
        <div className='col-lg-3'></div>
      </div>
    </div>
    <div className='container'>
      <p>A trust is essentially a relationship among three parties: the donor of some assets, the trustees who hold the assets, and the beneficiaries (those people who are eligible to benefit from the charity). When the trust has charitable purposes, and is a charity, the trust is known as a charitable trust.</p>
      <p>A charitable organization[1] or charity is an organization whose primary objectives are philanthropy and social well-being (e.g. educational, religious or other activities serving the public interest or common good).</p>
    </div>
    </div>
    <Footer/>
    </>
  )
}
