import React from 'react'
import Navbar from '../components/navbar'
import Footer from '../components/FooterNew'
import Image from 'react-bootstrap/Image';

export default function History() {
  return (
    <>
      <Navbar />
      <div className='container mb-2'>
        <h2>History</h2>
        <div className='row'>
          <div className='col-lg-6 '>
            <Image src="https://source.unsplash.com/500x500/?donation,coins" />
          </div>
          <div className='col-lg-6 '>
            <p>A donation is a gift for charity, humanitarian aid, or to benefit a cause. A donation may take various forms, including money, alms, services, or goods such as clothing, toys, food, or vehicles. A donation may satisfy medical needs such as blood or organs for transplant.</p><br/>
            <p>It is possible to donate in the name of a third party, making a gift in honor or in memory of someone or something. Gifts in honor or memory of a third party are made for various reasons, such as holiday gifts, wedding gifts, in memory of somebody who has died, in memory of pets or in the name of groups or associations no longer existing. Memorial gifts are sometimes requested by their survivors</p><br/>
            <p> Many donors such as public figures or philanthropists like to stay anonymous while making generous donations according to their wishes. Many donors like to stay anonymous because of their religious beliefs or simply don't want any notoriety from giving.</p>

          </div>
        </div>
      </div>
      <Footer />

    </>
  )
}
