import React from 'react'
import Navbar from '../components/navbar'
import Footer from '../components/FooterNew'

export default function Education() {
    return (
        <>
            <Navbar />
            <div className='container mt-2'>
                <div className='row'>
                    <div className='col-lg-5'></div>
                    <div className='col-lg-4 pl-5'>
                        <h2>Education</h2>
                    </div>
                    <div className='col-lg-3'></div>
                </div>
            </div>
            <div className='container mb-3 mt-3'>
                <div className='row'>
                    <div className='col-lg-6'>
                        <p>
                            Education is the transmission of knowledge, skills, and character traits. There are many debates about its precise definition, for example, about which aims it tries to achieve. <br /><br />A further issue is whether part of the meaning of education is that the change in the student is an improvement. Some researchers stress the role of critical thinking to distinguish education from indoctrination. These disagreements affect how to identify, measure, and improve forms of education. The term can also refer to the mental states and qualities of educated people. Additionally, it can mean the academic field studying education.

                            There are many types of education. <br /><br />There are many classifications of education. It depends on the institutional framework whether education is formal, non-formal, or informal
                        </p>
                    </div>
                    <div className='col-lg-6'>
                        <img src="https://tse3.mm.bing.net/th?id=OIP.2sBYhiTrVcwPof2nWwS55wHaE8&pid=Api&P=0&h=180" className="d-block w-100" alt="..." />
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
