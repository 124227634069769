import React from 'react'
import Navbar from '../components/navbar'
import Footer from '../components/FooterNew'

export default function Testmonial() {
  return (
    <>
    <Navbar/>
    <div className='container mt-2'>
      <div className='row'>
        <div className='col-lg-4'></div>
        <div className='col-lg-4 pl-5'>
          <h2>TestMonials</h2>
        </div>
        <div className='col-lg-3'></div>
      </div>
    </div>
    <div className='container-fluid mt-5 mb-3'>
        <div className='row'>
    <div class="col-lg-4">
    <img src="https://tse1.mm.bing.net/th?id=OIP.GWXtSU4Xuvvy-Zueq5JxNgHaE8&pid=Api&P=0&h=180" className=" bd-placeholder-img rounded-circle"  width="140" height="140" alt="..."/>
        {/* <svg class="bd-placeholder-img rounded-circle" width="140" height="140" xmlns="https://source.unsplash.com/800x200/?nature" role="img" aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="https://source.unsplash.com/800x200/?nature"></rect></svg> */}
        <h2 class="fw-normal">Testmonial1</h2>
        <p>"You should always include testimonials in your copy. The reader finds it easier to believe the endorsement of a fellow consumer than the puffery of an anonymous copywriter."</p>
        <p><a class="btn btn-secondary" href="#">View details »</a></p>
      </div>
      <div class="col-lg-4">
      <img src="https://tse3.mm.bing.net/th?id=OIP.FuqBG4h_qwRKanLYT79Y6wHaGS&pid=Api&P=0&h=180" className=" bd-placeholder-img rounded-circle"  width="140" height="140" alt="..."/>
        {/* <svg class="bd-placeholder-img rounded-circle" width="140" height="140" xmlns="https://source.unsplash.com/800x200/?nature,water" role="img" aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="https://source.unsplash.com/800x200/?nature,Mountains"></rect></svg> */}
        <h2 class="fw-normal">Testmonial2</h2>
        <p>"Writing case studies was a daunting task for us. We didn’t know where to begin or what questions to ask, and clients never seemed to follow through when we asked."</p>
        <p><a class="btn btn-secondary" href="#">View details »</a></p>
      </div>
      <div class="col-lg-4">
      <img src="https://tse3.mm.bing.net/th?id=OIP.YdOzcLA_cMVdE4u2GwdfugHaFD&pid=Api&P=0&h=180" className=" bd-placeholder-img rounded-circle"  width="140" height="140" alt="..."/>
        {/* <svg class="bd-placeholder-img rounded-circle" width="140" height="140" xmlns="https://source.unsplash.com/800x200/?nature,Mountains" role="img" aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="var(--bs-secondary-color)"></rect></svg> */}
        <h2 class="fw-normal">Testmonial3</h2>
        <p>"Their work on our website and Internet marketing has made a significant different to our business. We’ve seen a 425% increase in quote requests from the website which has been pretty remarkable – but I’d always like to see more!"</p>
        <p><a class="btn btn-secondary" href="#">View details »</a></p>
      </div>
      </div>
    </div>
    <Footer/>
    
    </>
  )
}
