import React from 'react'
import { useNavigate } from "react-router-dom";
import Navbar from '../components/navbar';
import Footer from '../components/FooterNew';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';


export default function Mission() {
  const navigate = useNavigate();
  function mission() {
    navigate("/Mission")
  }
  return (

    <>
     <Navbar />
      <div className='container mt-5 mb-5'>
        <div className='row '>
          <div className='col-md-4 '>
           
            <Card style={{ width: '18rem' }}>
              <Card.Img variant="top" src="https://source.unsplash.com/80x50/?mountains" />
              <Card.Body>
                <Card.Title>Mission</Card.Title>
                <Card.Text>
                Relief USA provides relief and development in a dignified manner regardless of gender, race, or religion.
                </Card.Text>
                <Button variant="primary">Go somewhere</Button>
              </Card.Body>
            </Card>
          </div>
          <div className='col-md-4'>
            <Card style={{ width: '18rem' }}>
              <Card.Img variant="top" src="https://source.unsplash.com/80x50/?nature,phone" />
              <Card.Body>
                <Card.Title>Vision</Card.Title>
                <Card.Text>
                Working together for a world free of poverty.
                and works to empower individuals in their communities and give them a voice in the world
                </Card.Text>
                <Button variant="primary">Go somewhere</Button>
              </Card.Body>
            </Card>
          </div>
          <div className='col-md-4'>
            <Card style={{ width: '18rem' }}>
              <Card.Img variant="top" src="https://source.unsplash.com/80x50/?nature,homes" />
              <Card.Body>
                <Card.Title>values</Card.Title>
                <Card.Text>
                These traits express the belief and define the culture of the organization
                need to fulfill our obligations to humanity.
                </Card.Text>
                <Button variant="primary">Go somewhere</Button>
              </Card.Body>
            </Card>
          </div>

        </div>
      </div>
      <Footer />
    </>
  )
}
