import React from 'react'
import Navbar from '../components/navbar'
import Footer from '../components/FooterNew'
import Image from 'react-bootstrap/Image';
import Col from 'react-bootstrap/Col';

export default function Motivation() {
    return (
        <>
            <Navbar />
            <div className='container mt-5 mb-2'>
                <div className='row'>
                    <div className='col-lg-6'>
                        <Image src="https://source.unsplash.com/80x50/?motivation,book" className="d-block w-100 h-50" />
                    </div>
                    <div className='col-lg-6 p'>
                        <p>There are scores of motivational quotes which have inspired both great and common people as well. They have been said through experience, by reading from books and through word of mouth. Nevertheless, motivational quotes are highly inspiring when given at the right time. Success does not come overnight and it needs a lot of hard work, over coming hurdles to climb up the ladder. It only needs 100 percent tireless effort. “Shoot for the moon, even if you miss, you will land among the stars” said Les Brown.

                        </p>
                    </div>
                </div>
                <div className='row gx-4'>
                <div className='col-lg-6 mt-5'>
<p>The purpose of this study was to find the level of orphans’ motivation for higher education and factors contributing their motivation for higher education. </p>
                </div>
                <div className='col-lg-6 pb-5 pl-15 pt-0 '>
                <Col xs={10} md={8}>
          <Image src="https://source.unsplash.com/80x50/?pen,book" roundedCircle className="d-block w-100 h-200 pb-10 pr-0" />
        </Col>
                </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
