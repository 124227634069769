import React from 'react'
import Navbar from './navbar'
import Footer from './FooterNew'

export default function Policy() {
    return (
        <>
            <Navbar />
            <div className='container mt-3 ml-40'>
                <div className='row gy-5'>
                    <div className='col-lg-6 pl-5 mt-10'>
                        <h2>Terms and Conditions</h2>
                        <p>Creating a Terms & Conditions for your application or website can take a lot of time. You could either spend tons of money on hiring a lawyer, or you could simply use our service and get a unique Terms & Conditions fully customized to your website.</p>
                        <p>These terms and conditions only apply to organizations that join GlobalGiving.
                            Please also refer to our general terms of service for our website.</p>
                    </div>
                    <div className='col-lg-6 pl-5 mt-10 '>
                        <img src="https://source.unsplash.com/400x200/?text,term,conditions" className="d-block w-100" alt="..." />
                    </div>
                </div>
                <div className='container mt-3 mb-3'>
                    <div className='row gy-5 '>
                        <div className='col-lg-6 pl-5 mt-10 '>
                            <img src="https://source.unsplash.com/400x200/?book,pen,Privacy" className="d-block w-100" alt="..." />
                        </div>

                        <div className='col-lg-6 pl-5'>
                            <h2>Privacy Policy</h2>
                            <p>Some third party services require you to have a Terms & Conditions The use of ads, analytics or third party payments usually ask you for a Terms & Conditions. Google Ads might be the one asking for your Terms & Conditions, or Amazon, and many more</p>
                            <p>If you have any questions about our Privacy Policy, please contact us.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}
