import React from 'react'
import Navbar from './navbar'
import Footer from './FooterNew'
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Receipt from './Receipt.jpg'

export default function Donate() {
    return (
   <>
            <Navbar />
            <div className='container mt-5 mb-5'>
                <div className='row'>
                    <div className='col-lg-6 '>
                        <Image src="https://source.unsplash.com/80x62/?donation,coins" className="d-block w-100 h-10" />
                    </div>
                   
                       

                            <div className='col-lg-6 do '>
                                {/* <h2>Please Donate</h2>
                                <Button variant="outline-warning b" className="ml-1">$5</Button>
                                <Button variant="outline-warning b" className="mr-1">$50</Button>
                                <Button variant="outline-warning b " className="ml-1">$500</Button>
                                <Button variant="outline-warning b " className="ml-1">$5000</Button> */}

                                <h3 className=''>All donations are Acknowledge and Issued Tax Credit Receipt as below</h3>
                            <img src={Receipt} height='300' width='auto'></img>
                            <h3>For Donations please Contact us</h3>
                            </div>
                          


                           
                        </div>
                    </div>
                    
                    <Footer />

                </>
                )
}
