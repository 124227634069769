import React from 'react'
import Navbar from '../components/navbar'
import Footer from '../components/FooterNew'
import axios from 'axios';
import { useEffect, useState } from 'react';
import Input from '../components/Input';

export default function Contact() {
  const [properties, setProperties] = useState([]);

  const [data, setData] = useState({
    firstName: '',
    lastName: '',
    email: '',

    phoneNumber: '',
    occupation: '',
    country: '',
    comment: '',
    appCode: "KPJ",
   preferedcontact: ''


  });

  const handleInput = (event) => {
    setData({ ...data, [event.target.name]: event.target.value })

  }

  const handleonClick = (event) => {
       event.preventDefault()
    let isvalid = validateForm();
    if (isvalid) {

    axios.post('http://34.199.176.17:13060/contactUS/saveContactData', data)
      .then(response => {
        setProperties(response.data)
        setData(
          {
            firstName: '',
            lastName: '',
            email: '',

            phoneNumber: '',
            occupation: '',
            country: '',
            comment: '',
            appCode: "KPJ",

          })
      })
      .catch(err => console.log(err))
    alert("your details are added successfully")

    } else {
        alert("please fill the all the required fields")
    }

  };

  const [inputerr, setInputerr] = useState({})

  const validateForm = () => {
      let err = {};

      if (data.firstName === '') {
          err.firstName = '*First name is required!';
      }
      if (data.lastName === '') {
          err.lastName = '*Last name is required!';
      }
      if (data.email === '') {
          err.email = '*Email ID is required!';
      }
      if (data.comment === '') {
          err.comment = '*Comments are required!';
      }
      if (data.phoneNumber === '') {
          err.phoneNumber = '*Phone Number is required!';
      }

     
     

      setInputerr({ ...err });
      return Object.keys(err).length === 0; // Return true if there are no validation errors
  };





  return (
    <>
      <Navbar />

      <div className='container-fluid bg-light'>
        <div className='row'>
          <div className='col-lg-6 d-flex flex-column justify-content-center align-items-center'>

            <div className="sect-7-heading mb-lg-4 text-lg-start text-center">
             <h2>Contact Our <br/>Corporate Offices Now.</h2> 
            </div>



          </div>
          <div className='col-lg-6 '>
            <h4  className='mb-3 ms-5'>Contact Us</h4>
            <div className='container ms-5'>
              <div className='row '>
                <div className='col-lg-6 '>
                  <h6 className='mb-0 ms-2'>FirstName</h6>
                  <Input type="text" name="firstName" placeholder="First Name" value={data.firstName} change={handleInput} />
                  {inputerr.firstName && <h6 className='ValidationColor'>{inputerr.firstName}</h6>}
                </div>
              </div>

              <div className='row mt-2'>
                <div className='col-lg-6'>
                  <h6 className='mb-0 ms-2'>LastName</h6>
                  <Input type="text" name="lastName" placeholder="last Name" value={data.lastName} change={handleInput} />
                  {inputerr.lastName && <h6 className='ValidationColor'>{inputerr.lastName}</h6>}
                </div>
              </div>
              <div className='mt-2'>
                <h6 className='mb-0 ms-2'>Email</h6>
                <Input type="email" name="email" placeholder="Email ID" value={data.email} change={handleInput} />
                {inputerr.email && <h6 className='ValidationColor'>{inputerr.email}</h6>}
              </div>

              <div className='mt-2'>
                <h6 className='mb-0 ms-2'>PhoneNumber</h6>
                <Input type="text" name="phoneNumber" placeholder="phone Number" maxlength="10" value={data.phoneNumber} change={handleInput} />
                {inputerr.phoneNumber && <h6 className='ValidationColor'>{inputerr.phoneNumber}</h6>}
              </div>

              <div className='height mt-2'>
                <h6 className='mb-0 ms-2'>Comments</h6>
                <Input  type="text" name="comment" placeholder="Comments" value={data.comment} change={handleInput} className='commentk' />
                {inputerr.comment && <h6 className='ValidationColor'>{inputerr.comment}</h6>}

              </div>

            </div >
            <div className=" d-flex justify-content-center align-items-center sect-7-button mt-3 mb-3" onClick={handleonClick} >
         <button className='mb-0 '>Submit</button> 
        </div>

          </div>
          
        </div>




        
      </div>
      <Footer />
    </>
  )
}
