import React from 'react'
import Navbar from './navbar'
import Footer from './FooterNew1'
import Carousel from 'react-bootstrap/Carousel';

export default function Home() {
  return (
   <>
  <Navbar/>
   {/* <div id="carouselExample" classNameName="carousel slide">
  <div className="carousel-inner">
    <div className="carousel-item active mb-100 h">
      <img src="https://images.unsplash.com/photo-1607791330831-d3dcf312e2d1?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fGxhcHRvcCUyMHdhbGxwYXBlcnxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=600&q=60" className="d-block w-100" alt="..."/>
    </div>
    <div className="carousel-item">
      <img src="https://source.unsplash.com/900x500/nature,water" className="d-block w-100" alt="..."/>
    </div>
    <div className="carousel-item">
      <img src="https://images.unsplash.com/900x500/?nature,water" className="d-block w-100" alt="..."/>
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div> */}
<div className='container-fluid'>
     
     <Carousel fade>
       <Carousel.Item>
         <img
           className="d-block w-100 "
           src="https://source.unsplash.com/800x310/?nature,water"
           alt="First slide"
         />
         <Carousel.Caption>
           <h3>Charity</h3>
           <p>Please help to the charities</p>
         </Carousel.Caption>
       </Carousel.Item>
       <Carousel.Item>
         <img
           className="d-block w-100"
           src="https://source.unsplash.com/800x315/?mountains,nature"
           alt="Second slide"
         />
 
         <Carousel.Caption>
           <h3>Charity</h3>
           <p>Please Do not Waste food</p>
         </Carousel.Caption>
       </Carousel.Item>
       <Carousel.Item>
         <img
           className="d-block w-100"
           src="https://source.unsplash.com/800x315/?sun,river"
           alt="Third slide"
         />
 
         <Carousel.Caption>
           <h3>Charity</h3>
           <p>
          Please help to the charities
           </p>
         </Carousel.Caption>
       </Carousel.Item>
     </Carousel>
     </div>
<Footer/>
   </>
  )
}
