import './App.css';
import Navbar from './components/navbar';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import Home from './components/Home';
import Mission from './Mission/Mission';
import Footer from './components/FooterNew';
import Motivation from './Mission/Motivation';
import History from './Mission/History';
import Donate from './components/Donate';
import Contact from './Mission/Contact';
import Charities from './Services/Charities';
import Testmonial from './Services/Testmonial';
import Education from './Services/Education';
import Events from './Events/Events';
import Policy from './components/Policy';
import FooterNew1 from './components/FooterNew1';


function App() {
  return (
   <>
  
 <BrowserRouter>
   <Routes>
     
     <Route path="/Navbar" element={<Navbar/>}/> 
     <Route path="/" element={<Home/>}/> 
     <Route path="/Mission" element={<Mission/>}/> 
     <Route path="/Footer" element={<Footer/>}/> 
     <Route path="/Motivation" element={<Motivation/>}/> 
     <Route path="/History" element={<History/>}/>
     <Route path="/Donate" element={<Donate/>}/> 
     <Route path="/Contact" element={<Contact/>}/> 
     <Route path="/Charities" element={<Charities/>}/> 
     <Route path="/TestMonials" element={<Testmonial/>}/> 
     <Route path="/Education" element={<Education/>}/> 
     <Route path="/Events" element={<Events/>}/> 
     <Route path="/Policy" element={<Policy/>}/> 
     {/* <Route path="/Footer" element={<FooterNew1/>}/>  */}

     </Routes>
     </BrowserRouter> 
 
     {/* <Navbar/>
     <Home/>
     <Footer/>
     <Mission/> */}
    
   </>
  );
}


export default App;
