import React from 'react'
import Navbar from '../components/navbar'
import Footer from '../components/FooterNew'

export default function Events() {
    return (
        <>
            <Navbar />
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-5'></div>
                    <div className='col-lg-4 pl-5'>
                        <h2>Events</h2>
                    </div>
                    <div className='col-lg-3'></div>
                </div>
            </div>
            <div className='container '>
                <img src="https://images.squarespace-cdn.com/content/v1/62b9b4d122f580096d9cf3a7/f1db8c65-a065-41fb-994a-54d1c725e658/nonprofit+event+planning_opportunities.jpg?format=1500w" className="d-block w-100" alt="..." />
            </div>
            <div className='container mb-2'>

                <div class="feature col">
                    <div class="feature-icon d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-3">
                        {/* <svg class="bi" width="1em" height="1em"></svg> */}
                    </div>
                    <h3 class="fs-2 text-body-emphasis">Event</h3>
                    <p>Nonprofit events are more than just dates on the calendar that keep your nonprofit on supporters’ minds or fundraising tools to help you meet your end-of-year goals. Rather, nonprofit events serve a holistic purpose.</p>
                    <a href="#" class="icon-link">
                        Call to action
                        {/* <svg class="bi"><use xlink:href="#chevron-right"></use></svg> */}
                    </a>
                    <div class="feature col">
                        <div class="feature-icon d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-3">
                            {/* <svg class="bi" width="1em" height="1em"></svg> */}
                        </div>
                        <h3 class="fs-2 text-body-emphasis">Fundraising</h3>
                        <p>Fundraising. Fundraising is also a big element of nonprofit events, as people will be highly motivated to act on the cause they’re learning and talking about with your team. You might raise money by charging an event registration fee, holding a charity auction, or encouraging donations in creative ways throughout the event. </p>
                        <a href="#" class="icon-link">
                            Call to action
                            {/* <svg class="bi"><use xlink:href="#chevron-right"></use></svg> */}
                        </a>
                    </div>
                    <div class="feature col">
                        <div class="feature-icon d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-3">
                            {/* <svg class="bi" width="1em" height="1em"></svg> */}
                        </div>
                        <h3 class="fs-2 text-body-emphasis">Awareness-raising</h3>
                        <p>Awareness-raising. An event is an excellent chance to spread the word about your cause, whether through speakers, motivational videos, or one-on-one conversations. Educate your attendees about the problems your nonprofit is striving to solve for its beneficiaries—and how attendees can get involved! </p>
                        <a href="#" class="icon-link">
                            Call to action
                            {/* <svg class="bi"><use xlink:href="#chevron-right"></use></svg> */}
                        </a>
                    </div>
                    <div class="feature col">
                        <div class="feature-icon d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-3">
                            {/* <svg class="bi" width="1em" height="1em"></svg> */}
                        </div>
                        <h3 class="fs-2 text-body-emphasis">Volunteering</h3>
                        <p>Volunteering. As a nonprofit leader, you likely recognize that donations aren’t the only way to help your organization move its mission forward. Volunteering is also crucial to your organization being able to accomplish its goals, and the moving parts of running an event allow for numerous volunteer opportunities—whether you’re having volunteers decorate a venue, facilitate fundraising activities, or usher attendees to their seats.  </p>
                        <a href="#" class="icon-link">
                            Call to action
                            {/* <svg class="bi"><use xlink:href="#chevron-right"></use></svg> */}
                        </a>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
