import React from 'react'
import { useNavigate } from "react-router-dom";

export default function FooterNew1() {
    const navigate = useNavigate();
  function Contact(){
    navigate("/Contact")
}

function Policy(){
    navigate("/Policy")
}
  return (
    <>
    <footer class="my-0  pt-3 text-body-secondary text-center text-small bg-secondary ">
    <p className='addp mb-1'>Address :<br></br>
D.no 17-1-213-6,Venugopal Nagar,Anantapur,515005<br/>
Andhra Pradesh</p>
    <p class="mb-1">© 1997-2023 COUNCIL ON FOUNDATIONS.ALL RIGHTS RESERVED KPJ Foundation Org.
</p>

    <ul class="list-inline f">
      <li class="list-inline-item"><a href="/Policy" onClick={Policy} >Privacy Policy</a></li>
      <li class="list-inline-item"><a href="/Policy" onClick={Policy}>Terms and Conditions</a></li>
      <li class="list-inline-item"><a href="/Contact" onClick={Contact}>Contact</a></li>
    </ul>
  </footer>
    </>
  )
}
