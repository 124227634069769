import React from 'react'
import { useNavigate } from "react-router-dom";


export default function Navbar() {
    const navigate = useNavigate();
    function mission(){
      navigate("/Mission")
  }
  function home(){
    navigate("/")
}
function Motivation(){
    navigate("/Motivation")
}
function donations(){
    navigate("/Donate")
}
function Contact(){
    navigate("/Contact")
}
function History(){
    navigate("/History")
}
function Testmonial(){
    navigate("/Testmonials")
}
function Education(){
    navigate("/Education")
}
function Charities(){
    navigate("/Charities")
}
function Events(){
    navigate("/Events")
}
    return (
        <>

            <nav className="navbar navbar-expand-sm navbar-dark bg-dark ">
                <div className="container-fluid">
                    {/* <a className="navbar-brand" href="/Navbar">Navbar</a> */}
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <a className="nav-link active" aria-current="page" href="/" onClick={home}>Home</a>
                            </li>

                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    About Us
                                </a>
                                <ul className="dropdown-menu">
                                    <li><a className="dropdown-item" href="/Mission" onClick={mission}>Mission,values & vision</a></li>
                                    <li><a className="dropdown-item" href="/Motivation" onClick={Motivation}>Motivation</a></li>
                                    <li><a className="dropdown-item" href="/History" onClick={History} >History</a></li>
                                    <li><a className="dropdown-item" href="/FAQ">FAQ</a></li>
                                    <li><a className="dropdown-item" href="/Contact" onClick={Contact}>Contact Us</a></li>
                                </ul>
                            </li>

                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Services
                                </a>
                                <ul className="dropdown-menu">
                                    <li><a className="dropdown-item" href="/Testmonials" onClick={Testmonial}>Testmonials</a></li>
                                    <li><a className="dropdown-item" href="/Donate" onClick={donations}>Donations</a></li>
                                    <li><a className="dropdown-item" href="/educations" onClick={Education} >educations</a></li>
                                    <li><a className="dropdown-item" href="/Charities" onClick={Charities}>Public Charities</a></li>
                                </ul>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link " aria-current="page" href="/Events" onClick={Events}>Events</a>
                            </li>

                            {/* <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Events
                                </a>
                                <ul className="dropdown-menu">

                                    <li><a className="dropdown-item" href="/Localevents">Local events 2023</a></li>
                                    <li><a className="dropdown-item" href="/Webinars">Webinars</a></li>
                                    <li><a className="dropdown-item" href="/Events">Events</a></li>
                                    <li><a className="dropdown-item" href="/archievers">Achievers</a></li>
                                </ul>
                            </li> */}

                        </ul>
                        <form className="d-flex" role="search">
                            <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
                            <button className="btn btn-outline-success" type="submit">Search</button>
                        </form>
                        <button type="button" class="btn btn-danger mx-4" href="/Donations" onClick={donations}>Donations</button>
                    </div>
                </div>
            </nav>

        </>
    )
}
